import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import {
  DESCRIPTION_MONTHLY_CONTENT,
  DESCRIPTION_YEARLY_CONTENT,
  TITLE_BLOCKS_CONTENT,
  SWITCH_CONTENT,
} from "../../components/iframe/wa-hot-products-bundle-paywall/PriceList/index.content";
import Layout from "../../layouts/LayoutIframe";
import updateTariff from "../../components/iframe/wa-hot-products-bundle-paywall/PriceList/updateTariff";
import PriceList from "../../components/Paywalls/PriceList";
import "../../components/iframe/wa-hot-products-bundle-paywall/PriceList/index.scss";
import "../../components/iframe/wa-hot-products-bundle-paywall/index.scss";

const WaHotProductsBundlePaywall = () => {
  const { addProps, isRendered, paramsLink } = useQueryWaIframeParams(
    "wa-hot-products-bundle"
  );

  return isRendered ? (
    <Layout withTagManager={false} className="PgIWHPBP" turnOnEventView>
      <PriceList
        title="Pricing"
        descriptionsMonthly={DESCRIPTION_MONTHLY_CONTENT}
        tariffMonthly={updateTariff({ addProps, paramsLink })}
        descriptionsYearly={DESCRIPTION_YEARLY_CONTENT}
        tariffYearly={updateTariff({ addProps, paramsLink })}
        switchContent={SWITCH_CONTENT}
        titlesBlocks={TITLE_BLOCKS_CONTENT}
        customClass="PgIWHPBP-PriceList"
        isIframe
      />
    </Layout>
  ) : null;
};

export default WaHotProductsBundlePaywall;
